const INVALID_EMAIL_PASSWORD = 1;
const TOO_MANY_LOGIN_ATTEMPTS = 2;
const ORGANIZATION_NOT_FOUND = 3;
const USER_NOT_FOUND = 4;
const ORGANIZATION_NAME_REQUIRED = 5;
const ORGANIZATION_NAME_NOT_UNIQUE = 6;
const ENVIRONMENT_NAME_REQUIRED = 7;
const ENVIRONMENT_NAME_NOT_UNIQUE = 8;
const ENVIRONMENT_ALREADY_ACTIVATED = 9; 
const DSAPI_KEY_REQUIRED = 10;
const FRONTEND_KEY_REQUIRED = 11;
const BACKEND_KEY_REQUIRED = 12;


export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const DEFAULT = 500;

export function getUserFriendlyErrorMessage(
    code = DEFAULT,
    message = "",
    extra = null,
    messageOnly = true
  ) {
    code = parseInt(code);
    if (code === INVALID_EMAIL_PASSWORD) {
      message = `You entered an incorrect email or password.`;
    } else if (code === TOO_MANY_LOGIN_ATTEMPTS) {
      message =
        "Too many failed login attempts. Please try again later.";
    } else if (code === BAD_REQUEST) {
      message =
        "Bad Request";
    } else if (code === ORGANIZATION_NOT_FOUND) {
      message =
        "Organization Not Found";
    } else if (code === USER_NOT_FOUND) {
      message = `User Not Found`;
    } else if (code === ORGANIZATION_NAME_REQUIRED) {
      message = "Fill in 'Organization name'";
    } else if (code === ORGANIZATION_NAME_NOT_UNIQUE) {
      message = "Organization name already exists";
    } else if (code === ENVIRONMENT_NAME_REQUIRED) {
      message = "Fill in 'Environment name'";
    } else if (code === ENVIRONMENT_NAME_NOT_UNIQUE) {
      message = "Environment name already exists";
    } else if (code === ENVIRONMENT_ALREADY_ACTIVATED) {
      message = "Environment cannot be updated as it is already activated";
    } else if (code === DSAPI_KEY_REQUIRED) {
      message = "Fill in 'DS API Key'";
    } else if (code === FRONTEND_KEY_REQUIRED) {
      message = "Fill in 'Frontend subscription key'";
    } else if (code === BACKEND_KEY_REQUIRED) {
      message = "Fill in 'Backend subscription key'";
    } else if (code === DEFAULT) {
      message = "Internal Server Error";
    } else {
      message = "Internal Server Error";
    }
    if (messageOnly) return message;
    return `Error Code: ${code} - ${message}`;
  }
  
  