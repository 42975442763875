import React, {Component} from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Redirect} from "react-router-dom";
import {checkJwt} from "../../utils/common";
import FlatList from "flatlist-react";
import "./organizationinfo.scss";
import downArrow from "../../images/chevron-down.png";
import upArrow from "../../images/chevron-up.png";
import searchIcon from "../../images/search.png";
import checkmark from "../../images/checkmark.png";
import {apiCall} from "../../utils/restclient";
import Loader from "react-loader-spinner";
import {getUserFriendlyErrorMessage} from "../../utils/errormessages";
import SidePanel from "../../lib/side-panel/sidepanel";
import Button from "../../lib/button/button";
import saveIcon from "../../images/save_icon_master.png";
import addButton from "../../images/add.png";
import close from "../../images/close.png";
import flagIcon from "../../images/flag.svg";

import PopUpMessage from "../../lib/pop-up-message/popupmessage";

const CUSTOM_ATTRIBUTE_ERROR = 1;
const RATING_CATEGORY_ERROR = 2;
const RATING_SOURCE_ERROR = 3;
const NOTE_TYPE_ERROR = 4;

class OrganizationInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jwt: "",
            logout: false,
            loading: false,
            popUpMessage: null,
            environmentNameError: false,
            organizationNameError: false,
            organizations: [],
            warningMessage: "",
            showOrganizations: false,
            organizationSelected: false,
            showCreateOrganization: false,
            createEnvError: false,
            createOrgError: false,
            createDSAPIError: false,
            createFrontendError: false,
            createBackendError: false,
            createError: "",
            canCreate: false,
            showTroubleIssues: false,
            customAttributesOpened: false,
            ratingCategoriesOpened: false,
            ratingSourcesOpened: false,
            noteTypesOpened: false,
        };
        this.organizations = [];
    }

    componentDidMount() {
        window.onbeforeunload = function () {
            if (this.isEdited)
                return "Organization changes have not been saved.\nDo you wish to continue without saving?";
        }.bind(this);
        let jwt = checkJwt();
        if (jwt === false) {
            this.setState({logout: true});
        } else {
            this.setState({jwt, loading: true});
            let getOrganizationsUrl = "nxt/v1/organizations";

            apiCall(getOrganizationsUrl, "GET", {
                Authorization: "Bearer " + jwt,
            })
                .then((getOrganizationResponse) => {
                    this.setState({loading: false});
                    this.organizations = getOrganizationResponse.sort((a, b) => {
                        if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
                            return -1;
                        }
                        if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    const savedOrganization = JSON.parse(localStorage.getItem('UserSelectedOrganization'))
                    if (savedOrganization) {
                        this._organizationSelected(savedOrganization)
                    }
                })
                .catch((error) => {
                    this.setState({loading: false});
                    if (error.ErrorCode === "Unauthorized") {
                        localStorage.removeItem("jwt");
                        let {hostname} = new URL(window.location);
                        window.location = hostname;
                    } else {
                        this.setState({popUpMessage: getUserFriendlyErrorMessage()});
                        setTimeout(() => {
                            this.setState({popUpMessage: null});
                        }, 2000);
                    }
                });
        }
    }

    render() {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    minWidth: 1100,
                    minHeight: 244,
                    height: "100%",
                    backgroundColor: "white",
                    flexDirection: "row",
                }}
                onClick={() => {
                    if (this.state.showOrganizations) {
                        document.getElementById("search").value = "";
                        document.getElementById("organizations-container").scrollTop = 0;

                        this.setState({
                            showOrganizations: !this.state.showOrganizations,
                        });
                    }
                }}
            >
                {this._showCreateOrganization()}
                {this._showViewTroubleShoot()}
                {this.state.popUpMessage ? (
                    <PopUpMessage
                        error={this.state.error}
                        popUpMessage={this.state.popUpMessage}
                    />
                ) : null}
                {this.state.warningMessage ? (
                    <div className="warning-container">
                        <div className="warning-box">
                            <label className="warning-message">
                                {this.state.warningMessage}
                            </label>
                            <div className="warning-buttons">
                                <div
                                    className="back-button"
                                    onClick={() => {
                                        this.setState({warningMessage: null});
                                    }}
                                >
                                    <label className="back-button-label">Go back</label>
                                </div>
                                <div
                                    className="confirm-button"
                                    onClick={() => {
                                        this.isEdited = false;
                                        this.previousAction();
                                        this.setState({warningMessage: null});
                                    }}
                                >
                                    <label className="confirm-button-label">Confirm</label>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.loading ? (
                    <div
                        style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            alignItems: "center",
                            left: 0,
                            right: 0,
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "white",
                            zIndex: 9000,
                            opacity: 0.5,
                        }}
                    >
                        <Loader
                            type="ThreeDots"
                            color="#003248"
                            height={100}
                            width={100}
                            //timeout={3000} //3 secs
                        />
                    </div>
                ) : null}
                {this.state.logout ? (
                    <Redirect
                        to={{
                            pathname: "/organizations",
                            state: {from: "/"},
                        }}
                    />
                ) : (
                    <div className="app-body">
                        <SidePanel
                            ref={(ref) => {
                                this.sidePanel = ref;
                            }}
                            active={"organizations"}
                            canRedirect={(section, force) => {
                                this._canRedirect(section, force);
                            }}
                            logout={() => {
                                this._logout();
                            }}
                        />
                        <div className="admin-main">
                            <div className="title-container">
                                <label className="title">Organization info</label>
                                <div class="title-right-container">
                                    <div
                                        class="add-organization-button-container"
                                        onClick={() => {
                                            this.setState({showCreateOrganization: true});
                                        }}
                                    >
                                        <img
                                            class="plus-button"
                                            alt="Add organization"
                                            src={addButton}
                                        />
                                    </div>
                                    <div
                                        className="org-picker-container-2"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <div
                                            className="org-picker"
                                            onClick={() => {
                                                document.getElementById("search").value = "";
                                                document.getElementById(
                                                    "organizations-container"
                                                ).scrollTop = 0;
                                                this.setState({
                                                    showOrganizations: !this.state.showOrganizations,
                                                });
                                            }}
                                        >
                                            <label
                                                className="select-organization"
                                                id="select-organization"
                                            >
                                                Select organization
                                            </label>
                                            <div className="arrow-container">
                                                <img
                                                    alt="Select organization"
                                                    src={downArrow}
                                                    className="down-arrow"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="search-results-container"
                                            style={{
                                                display: this.state.showOrganizations ? "flex" : "none",
                                            }}
                                        >
                                            <div className="search-container">
                                                <img
                                                    src={searchIcon}
                                                    alt="Search Icon"
                                                    className="search-icon"
                                                />
                                                <input
                                                    className="search"
                                                    placeholder="Search organization"
                                                    id="search"
                                                    onChange={() => {
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="organizations-container"
                                                id="organizations-container"
                                            >
                                                <FlatList
                                                    style={{height: 50}}
                                                    renderWhenEmpty={() => (
                                                        <div className="organization-list-container">
                                                            <div className="organization-list-separator"></div>
                                                            <div className="organization-item-container">
                                                                <label
                                                                    className="no-results"
                                                                    style={{fontWeight: null, color: "#333333"}}
                                                                >
                                                                    No Results
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                                    list={this.organizations.filter((item) => {
                                                        let searchText = document.getElementById("search");
                                                        if (searchText == null) {
                                                            return 1;
                                                        }
                                                        if (searchText.value === "") {
                                                            return 1;
                                                        } else if (
                                                            item.Name.toLowerCase().indexOf(
                                                                searchText.value.toLowerCase()
                                                            ) !== -1
                                                        ) {
                                                            return 1;
                                                        } else {
                                                            return 0;
                                                        }
                                                    })}
                                                    renderItem={this._renderOrganization}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!this.state.organizationSelected ? (
                                <label class="organization-not-selected">
                                    Please select an organization
                                </label>
                            ) : (
                                <div class="organization-info-container">
                                    <div className="general-info-container">
                                        <label class="info-title">General information</label>

                                        <label className="info-label">
                                            Organization name
                                            <label
                                                style={{
                                                    display: this.state.isEditable ? null : "none",
                                                    color: this.state.organizationNameError
                                                        ? "#ff3b30"
                                                        : "#333333",
                                                }}
                                            >
                                                *
                                            </label>
                                        </label>
                                        <input
                                            className="info-input"
                                            id="organization_name"
                                            name="organization_name"
                                            type="text"
                                            placeholder=""
                                            onChange={this._checkChanges}
                                            readOnly={true}
                                        />
                                        <label className="info-label">
                                            Environment name
                                            <label
                                                style={{
                                                    display: this.state.isEditable ? null : "none",
                                                    color: this.state.environmentNameError
                                                        ? "#ff3b30"
                                                        : "#333333",
                                                }}
                                            >
                                                *
                                            </label>
                                        </label>
                                        <input
                                            className="info-input"
                                            id="environment_name"
                                            name="environment_name"
                                            type="text"
                                            placeholder=""
                                            onChange={this._checkChanges}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="api-info-container">
                                        <label class="info-title">API Keys</label>
                                        <label className="info-label">DS API Key
                                            <label
                                                style={{
                                                    display: this.state.isEditable ? null : "none",
                                                    color: this.state.environmentNameError
                                                        ? "#ff3b30"
                                                        : "#333333",
                                                }}
                                            >
                                                *
                                            </label>
                                        </label>
                                        <input
                                            className="info-input"
                                            id="ds_api_key"
                                            name="ds_api_key"
                                            type="text"
                                            placeholder=""
                                            onChange={this._checkChanges}
                                            readOnly={true}
                                        />
                                        <label className="info-label">
                                            Frontend subscription key
                                            <label
                                                style={{
                                                    display: this.state.isEditable ? null : "none",
                                                    color: this.state.environmentNameError
                                                        ? "#ff3b30"
                                                        : "#333333",
                                                }}
                                            >
                                                *
                                            </label>
                                        </label>
                                        <input
                                            className="info-input"
                                            id="frontend_subscription_key"
                                            name="frontend_subscription_key"
                                            type="text"
                                            placeholder=""
                                            onChange={this._checkChanges}
                                            readOnly={true}
                                        />
                                        <label className="info-label">
                                            Backend subscription key
                                            <label
                                                style={{
                                                    display: this.state.isEditable ? null : "none",
                                                    color: this.state.environmentNameError
                                                        ? "#ff3b30"
                                                        : "#333333",
                                                }}
                                            >
                                                *
                                            </label>
                                        </label>
                                        <input
                                            className="info-input"
                                            id="backend_subscription_key"
                                            name="backend_subscription_key"
                                            type="text"
                                            placeholder=""
                                            onChange={this._checkChanges}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            )}
                            {!this.state.organizationSelected ? null : (
                                <div className="organization-buttons-container">
                                    <div
                                        class="troubleshoot-button-container"
                                        style={{
                                            opacity:
                                                !this.state.canSave && !this.state.isEditable ? 1 : 0.5,
                                            pointerEvents:
                                                !this.state.canSave && !this.state.isEditable
                                                    ? "auto"
                                                    : "none",
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                this._checkTroubleshoot();
                                            }}
                                            type="primary"
                                            labelContent={() => {
                                                return (
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: 56,
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <img
                                                            class="org-flag-icon"
                                                            src={flagIcon}
                                                            alt="Troubleshoot"
                                                        />
                                                        <label class="troubleshoot-org-label">
                                                            Troubleshoot
                                                        </label>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>

                                    {!this.state.isEditable ? null : (
                                        <div
                                            class="save-button-container"
                                            style={{
                                                opacity: this.state.canSave ? 1 : 0.5,
                                                pointerEvents: this.state.canSave ? "auto" : "none",
                                            }}
                                        >
                                            <Button
                                                onClick={() => {
                                                    this._saveChanges();
                                                }}
                                                type="primary"
                                                labelContent={() => {
                                                    return (
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: 56,
                                                                position: "relative",
                                                            }}
                                                        >
                                                            <img
                                                                class="org-save-icon"
                                                                src={saveIcon}
                                                                alt="Save changes"
                                                            />
                                                            <label class="save-org-label">
                                                                Save all changes
                                                            </label>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    _checkTroubleshoot = () => {
        const getTroubleshootingIssuesEndpoint = `nxt/v1/organizations/${this.state.organizationSelected.OrganizationId}/troubleshoot`;
        this.setState({
            loading: true,
            customAttributesOpened: false,
            ratingCategoriesOpened: false,
            ratingSourcesOpened: false,
            noteTypesOpened: false,
        });
        apiCall(
            getTroubleshootingIssuesEndpoint,
            "PUT",
            {
                Authorization: "Bearer " + this.state.jwt,
            },
            JSON.stringify({})
        )
            .then((issues) => {
                if (
                    issues.CustomAttributeCategoryErrors.length === 0 &&
                    issues.RatingCategoryErrors.length === 0 &&
                    issues.RatingSourceErrors.length === 0 &&
                    issues.NoteTypeErrors.length === 0
                ) {
                    this.setState({
                        loading: false,
                        popUpMessage: "No errors. Organization configuration is correct.",
                    });
                    setTimeout(() => {
                        this.setState({popUpMessage: null, error: false});
                    }, 2000);
                } else {
                    issues.CustomAttributeCategoryErrors.map((issue) => {
                        issue.Type = CUSTOM_ATTRIBUTE_ERROR;
                    });
                    issues.RatingCategoryErrors.map((issue) => {
                        issue.Type = RATING_CATEGORY_ERROR;
                    });
                    issues.RatingSourceErrors.map((issue) => {
                        issue.Type = RATING_SOURCE_ERROR;
                    });
                    issues.NoteTypeErrors.map((issue) => {
                        issue.Type = NOTE_TYPE_ERROR;
                    });
                    this.setState({
                        showTroubleIssues: true,
                        troubleshootIssues: issues,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                if (error.ErrorCode === "Unauthorized") {
                    localStorage.removeItem("jwt");
                    let {hostname} = new URL(window.location);
                    window.location = hostname;
                } else if (error.ErrorCode === "OrganizationNotFound") {
                    this.setState({
                        popUpMessage: getUserFriendlyErrorMessage(
                            error.UserFriendlyErrorCode
                        ),
                        error: true,
                        loading: false,
                    });
                    setTimeout(() => {
                        this.setState({popUpMessage: null, error: false});
                    }, 2000);
                }
            });
    };
    _renderTroubleshootCategories = (item) => {
        return (
            <div className="troubleshoot-row-container">
                <div className="troubleshoot-value-container">
                    <label className="troubleshoot-error-label">{item.Name}</label>
                </div>
                <div className="troubleshoot-value-container">
                    <label className="troubleshoot-error-label">{item.Type}</label>
                </div>
            </div>
        );
    };
    _renderTroubleshootCategoriesList = (item) => {
        return (
            <label className="troubleshoot-error-label troubleshoot-list-label">
                {item}
                <br/>
            </label>
        );
    };
    _renderTroubleshootErrors = (item, index) => {
        let description,
            cause = "";
        switch (item.Type) {
            case CUSTOM_ATTRIBUTE_ERROR:
                description = `Custom attribute ${item.Expected.Name} with type ${item.Expected.Type}. Invalid configuration`;
                cause = item.Similar
                    ? `Possibly custom attribute ${item.Similar.Name} with type ${item.Similar.Type} was configured wrong`
                    : "Attribute is not found";
                break;
            case RATING_CATEGORY_ERROR:
                description = `Rating ${item.Expected.Name} with type ${item.Expected.Type}. Invalid configuration`;
                cause = item.Similar
                    ? `Possibly rating ${item.Similar.Name} with type ${item.Similar.Type} was configured wrong`
                    : "Rating is not found";
                break;
            case RATING_SOURCE_ERROR:
                description = `Rating ${item.Name} with source ${item.Expected}. Invalid configuration`;
                cause = item.Similar
                    ? `Possibly source ${item.Similar} was named wrong`
                    : "Source is not found";
                break;
            case NOTE_TYPE_ERROR:
                description = `Note type ${item.Expected}. Invalid configuration`;
                cause = item.Similar
                    ? `Possibly type ${item.Similar} was named wrong`
                    : "Type is not found";
                break;
            default:
                description = `Custom attribute ${item.Expected.Name} with type ${item.Expected.Type}. Invalid configuration`;
                cause = item.Similar
                    ? `Possibly custom attribute ${item.Similar.Name} with type ${item.Similar.Type} was configured wrong`
                    : "Attribute is not found";
                break;
        }
        return (
            <div className="troubleshoot-error-row-container">
                <div className="troubleshoot-error-number-container">
                    <label className="troubleshoot-error-label">{index + 1}.</label>
                </div>
                <div className="troubleshoot-error-container">
                    <label className="troubleshoot-error-label">{description}</label>
                </div>
                <div className="troubleshoot-error-container">
                    <label className="troubleshoot-error-label">{cause}</label>
                </div>
            </div>
        );
    };

    _renderOrganization = (item, index) => {
        return (
            <div className="organization-list-container">
                <div className="organization-list-separator"></div>
                <div className="organization-item-container">
                    <label
                        className="organization-name"
                        style={{
                            fontWeight: item.IsChecked ? "bold" : null,
                            color: item.IsChecked ? "#003D4E" : "#333333",
                        }}
                        onClick={() => {
                            this.setState({organizationSelected: item});
                            setTimeout(() => {
                                this._organizationSelected(item);
                            }, 50);
                        }}
                    >
                        {item.Name}
                    </label>
                    {item.IsChecked ? (
                        <img
                            src={checkmark}
                            alt="Organization selected"
                            className="check-icon"
                        />
                    ) : null}
                </div>
            </div>
        );
    };

    _canRedirect = (section, force = false) => {
        if (this.isEdited && !force) {
            this.setState({
                warningMessage:
                    "Organization changes have not been saved.\nDo you wish to continue without saving?",
            });
            this.previousAction = () => {
                this.sidePanel._optionClicked(section, true);
            };
            return false;
        }
        this.sidePanel._optionClicked(section, true);
    };
    _createOrganization = () => {
        if (this.isEdited) {
            this.setState({
                warningMessage:
                    "Organization changes have not been saved.\nDo you wish to continue without saving?",
            });
            this.previousAction = () => {
                this._createOrganization();
            };
            return;
        }

        const createOrganizationEndpoint = "nxt/v1/organizations";
        this.setState({
            loading: true,
            createError: "",
            createBackendError: false,
            createDSAPIError: false,
            createFrontendError: false,
            createEnvError: false,
            createOrgError: false,
        });
        apiCall(
            createOrganizationEndpoint,
            "POST",
            {
                Authorization: "Bearer " + this.state.jwt,
            },
            JSON.stringify({
                Name: document.getElementById("create-org-name").value,
                EnvironmentName: document.getElementById("create-org-env").value,
                Settings: {
                    DonorSearchApiKey: document.getElementById("create-org-dsapi").value,
                    FrontendSubscriptionKey: document.getElementById("create-org-frontend")
                        .value,
                    BackendSubscriptionKey:
                    document.getElementById("create-org-backend").value,
                }
            })
        )
            .then((createOrganizationResponse) => {
                this.organizations = this.organizations.concat(
                    createOrganizationResponse
                );
                this._sortOrganizations();
                this.setState({
                    showCreateOrganization: false,
                    loading: false,
                    organizationSelected: createOrganizationResponse,
                    popUpMessage: "Organization created successfully",
                    error: false,
                    canCreate: false,
                });
                setTimeout(() => {
                    this._organizationSelected(createOrganizationResponse);
                }, 50);
                setTimeout(() => {
                    this.setState({popUpMessage: null});
                }, 2000);
                this._clearCreateForm();
            })
            .catch((error) => {
                this.setState({loading: false});
                if (error.ErrorCode === "Unauthorized") {
                    localStorage.removeItem("jwt");
                    let {hostname} = new URL(window.location);
                    window.location = hostname;
                } else if (error.ErrorCode) {
                    if (
                        error.ErrorCode === "OrganizationNameNotValid" ||
                        error.ErrorCode === "OrganizationNameNotUnique"
                    ) {
                        this.setState({createOrgError: true});
                    }
                    if (error.ErrorCode === "EnvironmentNameNotValid") {
                        this.setState({createEnvError: true});
                    }
                    if (error.ErrorCode === "DonorSearchApiKeyNotValid") {
                        this.setState({createDSAPIError: true});
                    }
                    if (error.ErrorCode === "FrontendSubscriptionKeyNotValid") {
                        this.setState({createFrontendError: true});
                    }
                    if (error.ErrorCode === "BackendSubscriptionKeyNotValid") {
                        this.setState({createBackendError: true});
                    }
                    this.setState({
                        createError: getUserFriendlyErrorMessage(
                            error.UserFriendlyErrorCode
                        ),
                        loading: false,
                    });
                }
            });
    };
    _checkCreateChanges = (e) => {
        if (
            document.getElementById("create-org-name").value.trim() === "" ||
            document.getElementById("create-org-env").value.trim() === "" ||
            document.getElementById("create-org-dsapi").value.trim() === "" ||
            document.getElementById("create-org-frontend").value.trim() === "" ||
            document.getElementById("create-org-backend").value.trim() === ""
        ) {
            if (this.state.canCreate) this.setState({canCreate: false});
        } else {
            if (!this.state.canCreate) this.setState({canCreate: true});
        }
    };
    _checkChanges = (e) => {
        if (
            this.state.organizationSelected.Name ===
            document.getElementById("organization_name").value &&
            this.state.organizationSelected.EnvironmentName ===
            document.getElementById("environment_name").value &&
            this.state.organizationSelected.Settings.BackendSubscriptionKey ===
            document.getElementById("backend_subscription_key").value &&
            this.state.organizationSelected.Settings.FrontendSubscriptionKey ===
            document.getElementById("frontend_subscription_key").value &&
            this.state.organizationSelected.Settings.DonorSearchApiKey ===
            document.getElementById("ds_api_key").value
        ) {
            this.isEdited = false;
            this.setState({canSave: false});
            return;
        } else if (
            this.state.organizationSelected.Name ===
            document.getElementById("organization_name").value &&
            this.state.organizationSelected.EnvironmentName === null &&
            "" === document.getElementById("environment_name").value
        ) {
            this.isEdited = false;
            this.setState({canSave: false});
            return;
        }
        if (
            document.getElementById("organization_name").value.trim() === "" ||
            document.getElementById("environment_name").value.trim() === "" ||
            document.getElementById("ds_api_key").value.trim() === "" ||
            document.getElementById("frontend_subscription_key").value.trim() === "" ||
            document.getElementById("backend_subscription_key").value.trim() === ""
        ) {
            this.setState({canSave: false});
        } else {
            this.setState({canSave: true});
        }

        this.isEdited = true;
    };
    _clearCreateForm = () => {
        document.getElementById("create-org-name").value = "";
        document.getElementById("create-org-env").value = "";
        document.getElementById("create-org-dsapi").value = "";
        document.getElementById("create-org-frontend").value = "";
        document.getElementById("create-org-backend").value = "";
        this.setState({
            canCreate: false,
            createError: "",
            createBackendError: false,
            createDSAPIError: false,
            createFrontendError: false,
            createEnvError: false,
            createOrgError: false,
        });
    };
    _organizationSelected = (org) => {
        if (this.isEdited) {
            this.setState({
                warningMessage:
                    "Organization changes have not been saved.\nDo you wish to continue without saving?",
            });
            this.previousAction = () => {
                this._organizationSelected(org);
            };
            return;
        }
        const getOrganizationUrl = `nxt/v1/organizations/${org.OrganizationId}`;
        this.setState({loading: true});
        apiCall(getOrganizationUrl, "GET", {
            Authorization: "Bearer " + this.state.jwt,
        })
            .then((getOrganizationResponse) => {
                this.setState({loading: false});
                this.organizations.map((organization, index) => {
                    if (organization.OrganizationId === org.OrganizationId) {
                        organization.IsChecked = true;
                        document.getElementById("select-organization").innerHTML =
                            getOrganizationResponse.Name;
                        document.getElementById("organization_name").value =
                            getOrganizationResponse.Name;
                        organization.Name = getOrganizationResponse.Name;
                        document.getElementById("environment_name").value =
                            getOrganizationResponse.EnvironmentName;
                        organization.EnvironmentName =
                            getOrganizationResponse.EnvironmentName;
                        document.getElementById("ds_api_key").value =
                            getOrganizationResponse.Settings.DonorSearchApiKey;
                        organization.Settings.DonorSearchApiKey =
                            getOrganizationResponse.Settings.DonorSearchApiKey;
                        document.getElementById("frontend_subscription_key").value =
                            getOrganizationResponse.Settings.FrontendSubscriptionKey;
                        organization.Settings.FrontendSubscriptionKey =
                            getOrganizationResponse.Settings.FrontendSubscriptionKey;
                        document.getElementById("backend_subscription_key").value =
                            getOrganizationResponse.Settings.BackendSubscriptionKey;
                        organization.Settings.BackendSubscriptionKey =
                            getOrganizationResponse.Settings.BackendSubscriptionKey;
                        this._setEditableFields(getOrganizationResponse.IsActivated);
                        localStorage.setItem('UserSelectedOrganization', JSON.stringify(getOrganizationResponse))
                        this.setState({
                            organizationSelected: {...organization},
                            environmentNameError: false,
                            organizationNameError: false,
                        });
                    } else organization.IsChecked = false;
                });
                this._sortOrganizations();

                this.isEdited = false;
            })
            .catch((error) => {
                this.setState({loading: false});
                if (error.ErrorCode === "Unauthorized") {
                    localStorage.removeItem("jwt");
                    let {hostname} = new URL(window.location);
                    window.location = hostname;
                } else if (error.ErrorCode === "OrganizationNotFound") {
                    this.setState({
                        popUpMessage: getUserFriendlyErrorMessage(
                            error.UserFriendlyErrorCode
                        ),
                        loading: false,
                        error: true,
                    });
                    setTimeout(() => {
                        this.setState({popUpMessage: null});
                    }, 2000);
                } else if (error.ErrorCode === "ServerError") {
                    this.setState({
                        popUpMessage: getUserFriendlyErrorMessage(
                            error.UserFriendlyErrorCode
                        ),
                        error: true,
                        loading: false,
                    });
                    setTimeout(() => {
                        this.setState({popUpMessage: null});
                    }, 2000);
                } else if (error.ErrorCode === "BadRequest") {
                    this.setState({
                        popUpMessage: getUserFriendlyErrorMessage(
                            error.UserFriendlyErrorCode
                        ),
                        error: true,
                        loading: false,
                    });
                    setTimeout(() => {
                        this.setState({popUpMessage: null});
                    }, 2000);
                }
            });
        document.getElementById("search").value = "";
        document.getElementById("organizations-container").scrollTop = 0;
        this.setState({showOrganizations: false, organizationSelected: org});
    };

    _saveChanges = () => {
        let isRequiredError = false;
        if (document.getElementById("organization_name").value.trim() === "") {
            isRequiredError = true;
            this.setState({organizationNameError: true});
        }
        if (document.getElementById("environment_name").value.trim() === "") {
            isRequiredError = true;
            this.setState({environmentNameError: true});
        }
        if (document.getElementById("ds_api_key").value.trim() === "") {
            isRequiredError = true;
            this.setState({environmentNameError: true});
        }
        if (document.getElementById("frontend_subscription_key").value.trim() === "") {
            isRequiredError = true;
            this.setState({environmentNameError: true});
        }
        if (document.getElementById("backend_subscription_key").value.trim() === "") {
            isRequiredError = true;
            this.setState({environmentNameError: true});
        }
        if (isRequiredError) {
            this.setState({
                popUpMessage: "Please fill all the required fields.",
                error: true,
            });
            setTimeout(() => {
                this.setState({popUpMessage: null});
            }, 2000);
            return;
        }
        this.setState({
            environmentNameError: false,
            organizationNameError: false,
        });

        const saveOrganizationUrl = `nxt/v1/organizations/${this.state.organizationSelected.OrganizationId}`;
        this.setState({loading: true});
        apiCall(
            saveOrganizationUrl,
            "PUT",
            {
                Authorization: "Bearer " + this.state.jwt,
            },
            JSON.stringify({
                Name: document.getElementById("organization_name").value,
                EnvironmentName: document.getElementById("environment_name").value,
                Settings: {
                    DonorSearchApiKey: document.getElementById("ds_api_key").value,
                    FrontendSubscriptionKey: document.getElementById("frontend_subscription_key").value,
                    BackendSubscriptionKey: document.getElementById("backend_subscription_key").value
                }
            })
        )
            .then(() => {
                this.isEdited = false;
                document.getElementById("select-organization").innerHTML =
                    document.getElementById("organization_name").value;
                this.setState({
                    loading: false,
                    popUpMessage: "All changes saved successfully",
                    error: false,
                    canSave: false,
                    organizationSelected: {
                        ...this.state.organizationSelected,
                        Name: document.getElementById("organization_name").value,
                        EnvironmentName: document.getElementById("environment_name").value,
                        Settings: {
                            BackendSubscriptionKey: document.getElementById("ds_api_key").value,
                            DonorSearchApiKey: document.getElementById("frontend_subscription_key").value,
                            FrontendSubscriptionKey: document.getElementById("backend_subscription_key").value,
                        }
                    },
                });
                this.organizations.map((org) => {
                    if (
                        org.OrganizationId ===
                        this.state.organizationSelected.OrganizationId
                    ) {
                        org.Name = document.getElementById("organization_name").value;
                        org.EnvironmentName =
                            document.getElementById("environment_name").value;
                    }
                });
                this._sortOrganizations();
                setTimeout(() => {
                    this.setState({popUpMessage: null});
                }, 2000);
            })
            .catch((error) => {
                this.setState({loading: false});
                if (error.ErrorCode === "Unauthorized") {
                    localStorage.removeItem("jwt");
                    let {hostname} = new URL(window.location);
                    window.location = hostname;
                } else if (error.ErrorCode) {
                    if (
                        error.ErrorCode === "EnvironmentNameNotValid" ||
                        error.ErrorCode === "EnvironmentNameNotUnique"
                    ) {
                        this.setState({environmentNameError: true});
                    }
                    if (
                        error.ErrorCode === "OrganizationNameNotValid" ||
                        error.ErrorCode === "OrganizationNameNotUnique"
                    ) {
                        this.setState({organizationNameError: true});
                    }
                    this.setState({
                        popUpMessage: getUserFriendlyErrorMessage(
                            error.UserFriendlyErrorCode
                        ),
                        loading: false,
                        error: true,
                    });
                    setTimeout(() => {
                        this.setState({popUpMessage: null});
                    }, 2000);
                }
            });
    };
    _setEditableFields = (value) => {
        document.getElementById("organization_name").readOnly = value;
        document.getElementById("environment_name").readOnly = value;
        document.getElementById("ds_api_key").readOnly = value;
        document.getElementById("frontend_subscription_key").readOnly = value;
        document.getElementById("backend_subscription_key").readOnly = value;

        this.setState({isEditable: !value, canSave: false});
    };
    _showCreateOrganization = () => {
        if (this.state.showCreateOrganization) {
            return (
                <div className="overlay-container">
                    <div className="create-organization-container">
                        <label className="create-organization-label">
                            Create organization
                        </label>
                        <img
                            className="create-close-image"
                            alt="Close"
                            src={close}
                            onClick={() => {
                                this.setState({showCreateOrganization: false});
                                this._clearCreateForm();
                            }}
                        />
                        <label className="create-error">{this.state.createError}</label>
                        <div className="organization-form">
                            <div className="organization-names-container">
                                <div className="create-organization-name-container">
                                    <label className="info-label">
                                        Organization name
                                        <label
                                            style={{
                                                color: this.state.createOrgError
                                                    ? "#ff3b30"
                                                    : "#333333",
                                            }}
                                        >
                                            *
                                        </label>
                                    </label>
                                    <input
                                        className="info-input"
                                        id="create-org-name"
                                        name="create-org-name"
                                        type="text"
                                        onChange={this._checkCreateChanges}
                                        placeholder="Organization name*"
                                    />
                                </div>
                                <div class="create-environment-name-container">
                                    <label className="info-label">
                                        Environment name
                                        <label
                                            style={{
                                                color: this.state.createEnvError
                                                    ? "#ff3b30"
                                                    : "#333333",
                                            }}
                                        >
                                            *
                                        </label>
                                    </label>
                                    <input
                                        className="info-input"
                                        id="create-org-env"
                                        name="create-org-env"
                                        type="text"
                                        onChange={this._checkCreateChanges}
                                        placeholder="Environment name*"
                                    />
                                </div>
                            </div>
                            <label className="info-label">
                                DS API Key
                                <label
                                    style={{
                                        color: this.state.createDSAPIError ? "#ff3b30" : "#333333",
                                    }}
                                >
                                    *
                                </label>
                            </label>
                            <input
                                className="info-input"
                                id="create-org-dsapi"
                                name="create-org-dsapi"
                                type="text"
                                onChange={this._checkCreateChanges}
                                placeholder="DS API Key*"
                            />
                            <label className="info-label">
                                Frontend subscription key
                                <label
                                    style={{
                                        color: this.state.createFrontendError
                                            ? "#ff3b30"
                                            : "#333333",
                                    }}
                                >
                                    *
                                </label>
                            </label>
                            <input
                                className="info-input"
                                id="create-org-frontend"
                                name="create-org-frontend"
                                type="text"
                                onChange={this._checkCreateChanges}
                                placeholder="Frontend subscription key*"
                            />
                            <label className="info-label">
                                Backend subscription key
                                <label
                                    style={{
                                        color: this.state.createBackendError
                                            ? "#ff3b30"
                                            : "#333333",
                                    }}
                                >
                                    *
                                </label>
                            </label>
                            <input
                                className="info-input"
                                id="create-org-backend"
                                name="create-org-backend"
                                type="text"
                                placeholder="Backend subscription key*"
                                onChange={this._checkCreateChanges}
                            />
                            <div className="create-organization-buttons-container">
                                <div className="create-cancel-button-container">
                                    <Button
                                        type="secondary"
                                        label="Cancel"
                                        onClick={() => {
                                            this.setState({showCreateOrganization: false});
                                            this._clearCreateForm();
                                        }}
                                    />
                                </div>
                                <div
                                    className="create-submit-button-container"
                                    style={{
                                        opacity: this.state.canCreate ? 1 : 0.5,
                                        pointerEvents: this.state.canCreate ? "auto" : "none",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        label="Save"
                                        onClick={() => {
                                            this._createOrganization();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
    _showViewTroubleShoot = () => {
        if (this.state.showTroubleIssues) {
            return (
                <div className="overlay-container">
                    <div className="troubleshoot-container">
                        <label className="troubleshoot-title-label">Troubleshooting</label>
                        <div className="troubleshoot-line"></div>
                        <img
                            className="troubleshoot-close-icon"
                            alt="Close"
                            src={close}
                            onClick={() => {
                                this.setState({showTroubleIssues: false});
                            }}
                        />
                        <div className="troubleshoot-content-container">
                            <div className="troubleshoot-section-container">
                                <div className="troubleshoot-section-title-container">
                                    <label className="troubleshoot-section-title">
                                        Errors found
                                    </label>
                                </div>
                                <div className="troubleshoot-section-header-container">
                                    <label class="troubleshoot-error-number-label">
                                        Error no.
                                    </label>
                                    <label class="troubleshoot-header-label">
                                        Error description
                                    </label>
                                    <label class="troubleshoot-header-label">
                                        Possible cause
                                    </label>
                                </div>
                                <FlatList
                                    style={{height: 80, paddingBottom: 32}}
                                    renderWhenEmpty={() => (
                                        <div className="troubleshoot-row-container">
                                            <div className="troubleshoot-value-container"></div>
                                            <div className="troubleshoot-value-container"></div>
                                        </div>
                                    )}
                                    list={[].concat([
                                        ...this.state.troubleshootIssues
                                            .CustomAttributeCategoryErrors,
                                        ...this.state.troubleshootIssues.RatingCategoryErrors,
                                        ...this.state.troubleshootIssues.RatingSourceErrors,
                                        ...this.state.troubleshootIssues.NoteTypeErrors,
                                    ])}
                                    renderItem={this._renderTroubleshootErrors}
                                />
                            </div>

                            <div className="troubleshoot-section-container">
                                <div className="troubleshoot-section-title-container">
                                    <label className="troubleshoot-section-title">
                                        Current custom attributes
                                    </label>
                                    <div>
                                        <img
                                            alt="Open"
                                            src={
                                                this.state.customAttributesOpened ? upArrow : downArrow
                                            }
                                            className="troubleshoot-down-arrow"
                                            onClick={() => {
                                                this.setState({
                                                    customAttributesOpened:
                                                        !this.state.customAttributesOpened,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                {!this.state.customAttributesOpened ? null : (
                                    <div>
                                        <div className="troubleshoot-section-header-container">
                                            <label class="troubleshoot-header-label">
                                                Custom attribute
                                            </label>
                                            <label class="troubleshoot-header-label">Type</label>
                                        </div>
                                        <FlatList
                                            renderWhenEmpty={() => (
                                                <div className="troubleshoot-row-container">
                                                    <div className="troubleshoot-value-container"></div>
                                                    <div className="troubleshoot-value-container"></div>
                                                </div>
                                            )}
                                            list={
                                                this.state.troubleshootIssues.CustomAttributeCategories
                                            }
                                            renderItem={this._renderTroubleshootCategories}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="troubleshoot-section-container">
                                <div className="troubleshoot-section-title-container">
                                    <label className="troubleshoot-section-title">
                                        Current rating categories
                                    </label>
                                    <div>
                                        <img
                                            alt="Open"
                                            src={
                                                this.state.ratingCategoriesOpened ? upArrow : downArrow
                                            }
                                            className="troubleshoot-down-arrow"
                                            onClick={() => {
                                                this.setState({
                                                    ratingCategoriesOpened:
                                                        !this.state.ratingCategoriesOpened,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                {!this.state.ratingCategoriesOpened ? null : (
                                    <div>
                                        <div className="troubleshoot-section-header-container">
                                            <label class="troubleshoot-header-label">
                                                Rating category
                                            </label>
                                            <label class="troubleshoot-header-label">
                                                Rating category type
                                            </label>
                                        </div>
                                        <FlatList
                                            renderWhenEmpty={() => (
                                                <div className="troubleshoot-row-container">
                                                    <div className="troubleshoot-value-container"></div>
                                                    <div className="troubleshoot-value-container"></div>
                                                </div>
                                            )}
                                            list={this.state.troubleshootIssues.RatingCategories}
                                            renderItem={this._renderTroubleshootCategories}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="troubleshoot-section-container">
                                <div className="troubleshoot-section-title-container">
                                    <label className="troubleshoot-section-title">
                                        Current rating sources
                                    </label>
                                    <div>
                                        <img
                                            alt="Open"
                                            src={this.state.ratingSourcesOpened ? upArrow : downArrow}
                                            className="troubleshoot-down-arrow"
                                            onClick={() => {
                                                this.setState({
                                                    ratingSourcesOpened: !this.state.ratingSourcesOpened,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                {!this.state.ratingSourcesOpened ? null : (
                                    <div className="troubleshoot-list-container">
                                        <FlatList
                                            renderWhenEmpty={() => (
                                                <div className="troubleshoot-row-container">
                                                </div>
                                            )}
                                            list={this.state.troubleshootIssues.RatingSources}
                                            renderItem={this._renderTroubleshootCategoriesList}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="troubleshoot-section-container">
                                <div className="troubleshoot-section-title-container">
                                    <label className="troubleshoot-section-title">
                                        Current note types
                                    </label>
                                    <div>
                                        <img
                                            alt="Open"
                                            src={this.state.noteTypesOpened ? upArrow : downArrow}
                                            className="troubleshoot-down-arrow"
                                            onClick={() => {
                                                this.setState({
                                                    noteTypesOpened: !this.state.noteTypesOpened,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                {!this.state.noteTypesOpened ? null : (
                                    <div className="troubleshoot-list-container">
                                        <FlatList
                                            renderWhenEmpty={() => (
                                                <div className="troubleshoot-row-container">
                                                </div>
                                            )}
                                            list={this.state.troubleshootIssues.NoteTypes}
                                            renderItem={this._renderTroubleshootCategoriesList}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
    _sortOrganizations = () => {
        this.organizations = this.organizations.sort((a, b) => {
            if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
                return -1;
            }
            if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    };

    _logout() {
        localStorage.removeItem("jwt");
        localStorage.removeItem("UserSelectedOrganization");
        this.setState({logout: true});
    }
}

export default OrganizationInfo;
