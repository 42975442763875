import React, { Component } from "react";
import "./button.scss";

class Button extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={
          this.props.type === "primary" ? "primary-button" : "secondary-button"
        }
        onClick={() => {
          this.props.onClick();
        }}
      >
        {this.props.labelContent ? (
          this.props.labelContent()
        ) : (
          <label
            className={
              this.props.type === "primary"
                ? "primary-label"
                : "secondary-label"
            }
          >
            {this.props.label}
          </label>
        )}
      </div>
    );
  }
}
export default Button;
