import React, { Component } from "react";
import { getVersion, getEnv } from "../../utils/buildinfo";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { apiCall } from "../../utils/restclient";
import { checkJwt } from "../../utils/common";
import "../../main.scss";
import "./login.scss";
import { Redirect } from "react-router-dom";
import moment from "moment";
import logo from "../../images/donorsearch_logo.png";
import Footer from "../../lib/footer/footer";
import Loader from "react-loader-spinner";
import {getUserFriendlyErrorMessage} from '../../utils/errormessages'


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      errorText: "",
      disabled: true,
      loading:false,
    };
    this.disabled = false;
  }

  componentDidMount() {
    this.disabled = false;
    if (checkJwt()) {
      this.setState({ redirect: true });
    }
    this._checkCanSubmit();
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          minWidth: 1100,
          minHeight: 644,
          height: "100%",
          backgroundColor: "white",
          flexDirection: "row",
        }}
      >
        {this.state.redirect ? (
          <Redirect
            to={{
              pathname: "/organizations",
              state: { from: "/" },
            }}
          />
        ) : (
          <div className="app">
          {this.state.loading ? (
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  left: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  zIndex: 1000,
                  opacity: 0.5,
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#003248"
                  height={100}
                  width={100}
                  //timeout={3000} //3 secs
                />
              </div>
            ) : null}
            <div className="side-panel">
              <div className="side-panel-header">
                <div className="half-triangle" />
                <div className="triangle" />
                <div className="triangle-2" />
                <div className="triangle-3" />
                <div className="half-triangle-2" />
              </div>
              <div className="side-panel-body">
                <figure>
                  <img className="logo" src={logo} alt="DonorSearch logo" />
                  <figcaption className="admin-label">Admin</figcaption>
                </figure>
              </div>
              <div className="side-panel-footer">
                <div className="half-triangle-3" />
                <div className="triangle-4" />
                <div className="triangle-5" />
                <div className="half-triangle-4" />
              </div>
            </div>
            <div className="main">
              <div className="main-body">
                <label className="login-title">Login</label>
                <label className="label-error">{this.state.errorText}</label>
                <form onSubmit={this._login}>
                  <label className="label-email" for="email">
                    Email
                  </label>
                  <br />
                  <input
                    className="input-email"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Email"
                    onChange={this._checkCanSubmit}
                  />
                  <label className="label-password" for="password">
                    Password
                  </label>
                  <br />
                  <input
                    className="input-password"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={this._checkCanSubmit}
                  />
                  <input
                    className="submit"
                    disabled={this.state.disabled}
                    type="submit"
                    value="Login"
                    id="submit"
                  />
                </form>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }

  _login = (e) => {
    if (e.target.email.value == "") {
      e.preventDefault();

      return;
    }
    if (!this.disabled) {
      this.disabled = true;
      let loginEndpoint = "v1/auth";
      //alert(e.target.email.value)
      this.setState({ errorText: "", loading:true });

      apiCall(
        loginEndpoint,
        "POST",
        null,
        JSON.stringify({
          Email: e.target.email.value,
          Password: e.target.password.value,
        })
      )
        .then((response) => {
          let jwtObject = {
            value: response.JwtAccessToken,
            timestamp:
              getEnv() === "Prod"
                ? moment().add(7, "days").toISOString()
                : moment().add(15, "minutes").toISOString(),
          };
          localStorage.setItem("jwt", JSON.stringify(jwtObject));
          this.setState({ redirect: true, loading:false });
          setTimeout(() => {
            this.disabled = false;
          }, 500);
        })
        .catch((error) => {
          this.setState({loading:false})
          if (error.ErrorCode === "CredentialsNotValid") {
            this.setState({
              errorText: getUserFriendlyErrorMessage(error.UserFriendlyErrorCode),
            });
          } else if(error.ErrorCode === 'AccountLocked')
          {
            this.setState({
              errorText: getUserFriendlyErrorMessage(error.UserFriendlyErrorCode),
            });
          } else {
            this.setState({ errorText: "Internal Server Error" });
          }
          setTimeout(() => {
            this.disabled = false;
          }, 500);
        });
    }
    e.preventDefault();
  };

  _checkCanSubmit = (e) => {
    let emailElement = document.getElementById("email");
    let passwordElement = document.getElementById("password");

    if (
      emailElement.value.trim() === "" ||
      passwordElement.value.trim() === ""
    ) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  };
}
export default Login;
