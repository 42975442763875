import React, { Component } from "react";
import errorIcon from "../../images/error.png";
import successIcon from "../../images/success.png";
import "./popupmessage.scss";
class PopUpMessage extends Component {
  render() {
    return (
      <div className={this.props.error ? "pop-up-error" : "pop-up-success"}>
        <img
          className="pop-up-icon"
          src={this.props.error ? errorIcon : successIcon}
          alt={this.props.error ? "Error" : "Success"}
        />
        <label className="pop-up-message">{this.props.popUpMessage}</label>
      </div>
    );
  }
}
export default PopUpMessage;
