import version from "../data/version.json";
import * as env from "../env.json";

export function getVersion() {
  return (
    version.majorNumber + "." + version.minorNumber + "." + version.buildNumber
  );
}
export function getEnv() {
  return env.name;
}
