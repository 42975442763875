import React, { Component } from "react";
import "./sidepanel.scss";
import { getVersion, getEnv } from "../../utils/buildinfo";
import dsLogo from "../../images/donorsearch_logo_small.png";

import FlatList from "flatlist-react";
import { Redirect } from "react-router-dom";

class SidePanel extends Component {
  constructor(props){
    super(props);
    this.state = {
      options: [
        { Name: "Organization info", Active: props.active ==='organizations', Route: 'organizations'},
        {Name: "Permissions", Active: props.active ==='permissions', Route:'permissions'},
        {Name: "Logout", Active: props.active === 'logout'}
      ],
    }
  }
  render() {
    if(this.state.redirect)
    {
      return(
        <Redirect
            to={{
              pathname: `/${this.state.redirect}`,
              state: { from: `/${this.props.active}` },
            }}
          />
      )
    }
    return(
    <div className="auth-side-panel">
    <img
      className="admin-logo"
      src={dsLogo}
      alt="DonorSearch small logo"
    />
    <div className="auth-side-panel-body">
    <FlatList
        list={this.state.options}
        renderItem={this._renderList}
      />
    </div>
  </div>
  )
  }
  _renderList = (item, index) => {
    let labelName = item.Active ? "current-option-name" : "option-name";
    let containerName = item.Active? "current-container" : "option-container"

    return (
      <div className={containerName}
       onClick={() => {
            if(item.Active)
              return
            this._optionClicked(item);
          }}>
        <div
          style={{
            width: 4,
            height: "100%",
            display: item.Active ? "flex" : "none",
            backgroundColor: "#003D4E",
            borderRadius: 2,
          }}
        ></div>
        
        <label
          className={labelName}
          style={{ fontWeight: item.Active ? "700" : "400", color:'#333333' }}
        >
          {item.Name}{" "}
        </label>
      </div>
    );
  };
  _optionClicked=(section, force=false)=>{
    this.section = section? section : this.section
    if(force || this.props.canRedirect(section, false))
    {
      if(this.section.Name !== 'Logout')
      {
        this.setState({redirect: this.section.Route.toLowerCase()})
      }
      else{
        this.props.logout()
      }
    }
  }
}
export default SidePanel;
