import * as env from "../env.json";
import {DEFAULT, BAD_REQUEST, UNAUTHORIZED} from "./errormessages"
let baseHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Origin: "*",
};

export async function apiCall(
  api,
  method,
  auth = {},
  body = null,
  withBase = null,
  headers = null,
  attempt = 0,
  isBlob = false
) {
  return new Promise(async (resolve, reject) => {
    let baseAPI = "";
    let requestHeaders = {};
    if (withBase) {
      baseAPI = withBase;
    } else {
      baseAPI = env.baseURL;
    }
    if (headers) {
      requestHeaders = { ...headers, ...auth };
    } else {
      requestHeaders = { ...baseHeaders, ...auth };
    }
    fetch(baseAPI + api, { method, headers: requestHeaders, body })
      .then(async (res) => {
        try {
          if( res.ok && api ==='nxt/v1/users/permissions' ){
            return { success: true };
          }
          if (
            res.ok &&
            res.headers.get("Content-Type") == null &&
            res.headers.get("Content-Length") == 0
          ) {
            return { success: true };
          }
          if(withBase === null && res.status === 401)
          {
            return Promise.reject({ErrorCode:"Unauthorized", UserFriendlyErrorCode: UNAUTHORIZED})
          }
          else if(withBase === null && res.status === 400 && api !=='v1/auth'){
            return Promise.reject({ErrorCode:"BadRequest", UserFriendlyErrorCode: BAD_REQUEST})
          }
          else if(withBase === null && res.status === 500){
            return Promise.reject({ErrorCode:"ServerError", UserFriendlyErrorCode:DEFAULT})
          }
          return res.json().then((json) => {
            return res.ok
              ? json
              : Promise.reject(json);
          });
        } catch (e) {
          return res.text().then((text) => {
            return res.ok ? text : Promise.reject({
              ErrorCode: "ServerError",
              UserFriendlyErrorCode: DEFAULT,
              Message: res.statusText,
            });;
          });
        }
      })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

