import React, { Component } from "react";
import "./footer.scss";
import { getVersion, getEnv } from "../../utils/buildinfo";
class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <label className="version">{`v(${getVersion()})(${getEnv()})`}</label>
      </div>
    );
  }
}
export default Footer;
