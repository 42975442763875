import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import Permissions from "./routes/permissions/permissions";
import Login from "./routes/login/login";
import OrganizationInfo from "./routes/organization-info/organizationinfo";
import { checkJwt } from "./utils/common";

const NotFoundRedirect = () => <Redirect to="/" />;

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/permissions">
        <Permissions />
      </PrivateRoute>
      <PrivateRoute path="/organizations">
        <OrganizationInfo />
      </PrivateRoute>
      <Route path="/" component={Login} />
      <Route component={NotFoundRedirect} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
function PrivateRoute({ children, ...rest }) {
  let auth = checkJwt();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
