import moment from "moment";

export function checkJwt() {
  let jwtObject = localStorage.getItem("jwt");

  if (jwtObject != null) {
   
    jwtObject = JSON.parse(jwtObject);
    let timestamp = jwtObject.timestamp;
    if (moment().isBefore(moment(timestamp))) {
      return jwtObject.value;
    } else {
      localStorage.removeItem("jwt");
      return false;
    }
  }
  return false;
}
