import React, {Component} from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Redirect} from "react-router-dom";
import {checkJwt} from "../../utils/common";
import FlatList from "flatlist-react";
import "./permissions.scss";
import saveIcon from "../../images/save_icon.png";
import downArrow from "../../images/down_selector.png";
import searchIcon from "../../images/search.png";
import checkmark from "../../images/checkmark.png";
import errorIcon from "../../images/error.png";
import successIcon from "../../images/success.png";
import Switch from "react-switch";
import {apiCall} from "../../utils/restclient";
import Loader from "react-loader-spinner";
import {getUserFriendlyErrorMessage} from "../../utils/errormessages";
import SidePanel from "../../lib/side-panel/sidepanel";

class Permissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: "",
      logout: false,
      loading: false,
      popUpMessage: null,
      options: [
        { Name: "Permissions", Active: true },
        { Name: "Logout", Active: false },
      ],
      organizations: [
      ],
       warningMessage:'',
      showOrganizations: false,
    };
    this.organizations = [];
    this.users = [];
    this.currentOrganizationIndex = -1;
    this.newChanges = [];
  }

  componentDidMount() {
    window.onbeforeunload = function () {
      if (this.newChanges.length !== 0)
        return "Permission changes have not been saved. Do you wish to leave the page without saving?";
    }.bind(this);

    let jwt = checkJwt();
    if (jwt === false) {
      this.setState({ logout: true });
    } else {
      this.setState({ jwt, loading: true });
      let getOrganizationsUrl = "nxt/v1/organizations";

      apiCall(getOrganizationsUrl, "GET", {
        Authorization: "Bearer " + jwt,
      })
        .then((getOrganizationResponse) => {
          this.setState({ loading: false });
          this.organizations = getOrganizationResponse.sort((a, b) => {
            if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
              return -1;
            }
            if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          const savedOrganization=JSON.parse(localStorage.getItem('UserSelectedOrganization'))
          if(savedOrganization) {
            this._organizationSelected(savedOrganization.OrganizationId)
          }
         })
        .catch((error) => {
          this.setState({ loading: false });
          if (error.ErrorCode === "Unauthorized") {
            localStorage.removeItem("jwt");
            let { hostname } = new URL(window.location);
            window.location = hostname;
          } else {
            this.setState({ popUpMessage: getUserFriendlyErrorMessage() });
            setTimeout(() => {
              this.setState({ popUpMessage: null });
            }, 2000);
          }
        });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          minWidth: 1100,
          minHeight: 244,
          height: "100%",
          backgroundColor: "white",
          flexDirection: "row",
        }}
        onClick={() => {
          if (this.state.showOrganizations) {
            document.getElementById("search").value = "";
            document.getElementById("organizations-container").scrollTop = 0;

            this.setState({
              showOrganizations: !this.state.showOrganizations,
            });
          }
        }}
      >
        {this.state.logout ? (
          <Redirect
            to={{
              pathname: "/permissions",
              state: { from: "/" },
            }}
          />
        ) : (
          <div className="app-body">
            {this.state.loading ? (
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  left: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  zIndex: 1000,
                  opacity: 0.5,
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#003248"
                  height={100}
                  width={100}
                  //timeout={3000} //3 secs
                />
              </div>
            ) : null}
            {this.state.popUpMessage ? (
              <div
                className={this.state.error ? "pop-up-error" : "pop-up-success"}
              >
                <img
                  className="pop-up-icon"
                  src={this.state.error ? errorIcon : successIcon}
                  alt={this.state.error ? "Error" : "Success"}
                />
                <label className="pop-up-message">
                  {this.state.popUpMessage}
                </label>
              </div>
            ) : null}
            {this.state.warningMessage ? (
              <div className="warning-container">
                <div className="warning-box">
                  <label className="warning-message">
                    {this.state.warningMessage}
                  </label>
                  <div className="warning-buttons">
                    <div
                      className="back-button"
                      onClick={() => {
                        this.setState({ warningMessage: null });
                      }}
                    >
                      <label className="back-button-label">Go back</label>
                    </div>
                    <div
                      className="confirm-button"
                      onClick={() => {
                        this.newChanges = [];
                        this.previousAction();
                        this.setState({ warningMessage: null });
                      }}
                    >
                      <label className="confirm-button-label">Confirm</label>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/*<div style={{position:"absolute", right:40, height:'100%', width:1, backgroundColor:'red'}}></div>*/}

            <SidePanel ref={(ref)=>{this.sidePanel = ref}}  active={"permissions"} canRedirect={(section, force)=>{this._canRedirect(section, force)}} logout={()=>{this._logout()}}/>

            <div className="admin-main">
              <div className="title-container">
                <label className="title">Permissions</label>
                <div
                  className="org-picker-container"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    className="org-picker"
                    onClick={() => {
                      document.getElementById("search").value = "";
                      document.getElementById(
                        "organizations-container"
                      ).scrollTop = 0;
                      this.setState({
                        showOrganizations: !this.state.showOrganizations,
                      });
                    }}
                  >
                    <label
                      className="select-organization"
                      id="select-organization"
                    >
                      Select organization
                    </label>
                    <div className="arrow-container">
                      <img
                        alt="Select organization"
                        src={downArrow}
                        className="down-arrow"
                      />
                    </div>
                  </div>
                  <div
                    className="search-results-container"
                    style={{
                      display: this.state.showOrganizations ? "flex" : "none",
                    }}
                  >
                    <div className="search-container">
                      <img
                        src={searchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                      <input
                        className="search"
                        placeholder="Search organization"
                        id="search"
                        onChange={() => {
                          this.forceUpdate();
                        }}
                      />
                    </div>
                    <div
                      className="organizations-container"
                      id="organizations-container"
                    >
                      <FlatList
                        style={{ height: 50 }}
                        renderWhenEmpty={() => (
                          <div className="organization-list-container">
                            <div className="organization-list-separator"></div>
                            <div className="organization-item-container">
                              <label
                                className="no-results"
                                style={{ fontWeight: null, color: "#333333" }}
                              >
                                No Results
                              </label>
                            </div>
                          </div>
                        )}
                        list={this.organizations.filter((item) => {
                          let searchText = document.getElementById("search");
                          if (searchText == null) {
                            return 1;
                          }
                          if (searchText.value === "") {
                            return 1;
                          } else if (
                            item.Name.toLowerCase().indexOf(
                              searchText.value.toLowerCase()
                            ) !== -1
                          ) {
                            return 1;
                          } else {
                            return 0;
                          }
                        })}
                        renderItem={this._renderOrganization}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-header">
                <label className="user-id-label">User ID</label>
                <label className="permissions-label">Permissions</label>
                <div
                  className={
                    this.newChanges.length === 0
                      ? "save-container disabled"
                      : "save-container"
                  }
                  onClick={() => {
                    this._savePermissions();
                  }}
                >
                  <img
                    alt="Save permissions"
                    src={saveIcon}
                    className="save-icon"
                  />
                  <label className="save-pref-label">Save Preferences</label>
                </div>
              </div>
              <div
                className="users-list-container"
                style={{
                  display:
                    this.currentOrganizationIndex === -1 ||
                    this.users.length === 0
                      ? "flex"
                      : "block",
                }}
              >
                {this.currentOrganizationIndex === -1 ? (
                  <label className="please-select">
                    Please select an organization
                  </label>
                ) : this.users.length ? (
                  <FlatList
                    style={{ height: 50, overflow: "auto" }}
                    list={this.users}
                    renderItem={this._renderUsersList}
                  />
                ) : (
                  <label className="no-users">
                    This organization has no users
                  </label>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  _changeUserPermission = (i) => {
    let isNew = true;
    for (
      let currentIndex = 0;
      currentIndex < this.newChanges.length;
      currentIndex++
    ) {
      if (this.newChanges[currentIndex].UserId === this.users[i].UserId) {
        isNew = false;
        this.newChanges.splice(currentIndex, 1);
        currentIndex = this.newChanges.length;
      }
    }
    if (isNew) {
      this.newChanges = this.newChanges.concat(this.users[i]);
    }
    this.users[i].IsDonorUpdateAllowed = !this.users[i].IsDonorUpdateAllowed;
    document.activeElement.blur();
    this.forceUpdate();
  };
  _renderOrganization = (item, index) => {
    return (
      <div className="organization-list-container">
        <div className="organization-list-separator"></div>
        <div className="organization-item-container">
          <label
            className="organization-name"
            style={{
              fontWeight: item.IsChecked ? "bold" : null,
              color: item.IsChecked ? "#003D4E" : "#333333",
            }}
            onClick={() => {
              this._organizationSelected(item.OrganizationId);
              localStorage.setItem('UserSelectedOrganization',JSON.stringify(item))
            }}
          >
            {item.Name}
          </label>
          {item.IsChecked ? (
            <img
              src={checkmark}
              alt="Organization selected"
              className="check-icon"
            />
          ) : null}
        </div>
      </div>
    );
  };
  _renderUsersList = (item, index) => {
    return (
      <div className="user-row">
        <label className="user-email">{item.Email}</label>
        <div className="switch-container">
          <Switch
            className="switch"
            width={56}
            height={28}
            offColor="#FF3B30"
            onColor="#34C759"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() => {
              this._changeUserPermission(index);
            }}
            checked={item.IsDonorUpdateAllowed}
          />
          <label className="switch-label">
            {item.IsDonorUpdateAllowed ? "Enabled" : "Disabled"}
          </label>
        </div>
      </div>
    );
  };
  _canRedirect(section, force = false){
    
      if (this.newChanges.length > 0 && !force) {
        this.setState({
          warningMessage:
            "Permission changes have not been saved. Do you wish to leave the page without saving?",
        });
        this.previousAction = () => {
          this.sidePanel._optionClicked(section, true)
        };
        return false;
      }
      this.sidePanel._optionClicked(section, true)
    
  };
  _organizationSelected = (organizationId) => {
    if (this.newChanges.length > 0) {
      this.setState({
        warningMessage:
          "Permission changes have not been saved. Do you wish to leave the page without saving?",
      });
      this.previousAction = () => {
        this._organizationSelected(organizationId);
      };
      return;
    }
    let orgIndex = 0;
    this.organizations.map((organization, index) => {
      if (organization.OrganizationId === organizationId) {
        organization.IsChecked = true;
        orgIndex = index;
        document.getElementById("select-organization").innerHTML =
          this.organizations[index].Name;
      } else organization.IsChecked = false;
    });
    this.setState({ loading: true });
    document.getElementById("search").value = "";
    document.getElementById("organizations-container").scrollTop = 0;
    let getUsersUrl = `nxt/v1/organizations/${organizationId}/users`;
    apiCall(getUsersUrl, "GET", {
      Authorization: "Bearer " + this.state.jwt,
    })
      .then((getUsersResponse) => {
        this.users = getUsersResponse.sort((a, b) => {
          if (a.Email.toLowerCase() < b.Email.toLowerCase()) {
            return -1;
          }
          if (a.Email.toLowerCase() > b.Email.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        this.currentOrganizationIndex = orgIndex;
        this.newChanges = [];
        this.setState({ loading: false, selectedOrganization: organizationId });
      })
      .catch((error) => {
        if (error.ErrorCode === "Unauthorized") {
          this.setState({ loading: false });
          localStorage.removeItem("jwt");
          let { hostname } = new URL(window.location);
          window.location = hostname;
        } else if (error.ErrorCode === "OrganizationNotFound") {
          this.setState({
            popUpMessage: getUserFriendlyErrorMessage(
              error.UserFriendlyErrorCode
            ),
            loading: false,
            error: true,
          });
          setTimeout(() => {
            this.setState({ popUpMessage: null });
          }, 2000);
        } else if (error.ErrorCode === "ServerError") {
          this.setState({
            popUpMessage: getUserFriendlyErrorMessage(
              error.UserFriendlyErrorCode
            ),
            error: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ popUpMessage: null });
          }, 2000);
        } else if (error.ErrorCode === "BadRequest") {
          this.setState({
            popUpMessage: getUserFriendlyErrorMessage(
              error.UserFriendlyErrorCode
            ),
            error: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ popUpMessage: null });
          }, 2000);
        }
      });
    this.setState({ showOrganizations: false });
  };
  _logout = ()=>{
    localStorage.removeItem("jwt");
    localStorage.removeItem("UserSelectedOrganization");
    this.setState({ logout: true });
  }
  _savePermissions = () => {
    if (this.newChanges.length === 0) return;
    let savePreferencesUrl = `nxt/v1/organizations/${this.state.selectedOrganization}/users`;
    this.setState({ loading: true });
    apiCall(
      savePreferencesUrl,
      "PUT",
      {
        Authorization: "Bearer " + this.state.jwt,
      },
      JSON.stringify(this.newChanges)
    )
      .then((saveResult) => {
        this.newChanges = [];
        this.setState({
          loading: false,
          popUpMessage: "Permissions saved successfully",
          error: false,
        });
        setTimeout(() => {
          this.setState({ popUpMessage: null });
        }, 2000);
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.ErrorCode === "Unauthorized") {
          localStorage.removeItem("jwt");
          let { hostname } = new URL(window.location);
          window.location = hostname;
        } else if (error.ErrorCode === "ServerError") {
          this.setState({
            popUpMessage: getUserFriendlyErrorMessage(
              error.UserFriendlyErrorCode
            ),
            error: true,
          });
          setTimeout(() => {
            this.setState({ popUpMessage: null });
          }, 2000);
        } else if (error.ErrorCode === "BadRequest") {
          this.setState({
            popUpMessage: getUserFriendlyErrorMessage(
              error.UserFriendlyErrorCode
            ),
            error: true,
          });
          setTimeout(() => {
            this.setState({ popUpMessage: null });
          }, 2000);
        } else if (error.ErrorCode === "OrganizationNotFound") {
          this.setState({
            popUpMessage: getUserFriendlyErrorMessage(
              error.UserFriendlyErrorCode
            ),
            error: true,
          });
          setTimeout(() => {
            this.setState({ popUpMessage: null });
          }, 2000);
        } else if (error.ErrorCode === "UserNotFound") {
          this.setState({
            popUpMessage: getUserFriendlyErrorMessage(
              error.UserFriendlyErrorCode
            ),
            error: true,
          });
          setTimeout(() => {
            this.setState({ popUpMessage: null });
          }, 2000);
        }
      });
  };
}
export default Permissions;
